import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { HomeFeature } from "../components/Home"
import SEO from "../components/SEO/seo"
import PopularIntegrations from "../components/popularIntegrations"
import { CardContainer, CommonCard } from "../components/commonFeatureComp"
import BgImgComp from "../components/bgImgComp"
import { GatsbyImage } from "gatsby-plugin-image"

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  margin-top: 80px;
`
const Title = styled.h2`
  font-size: 32px;
  color: #2f394e;
  text-align: center;
  padding: 0 15px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const Bottomline = styled.div`
  width: 50px;
  height: 3px;
  border-radius: 10px;
  background-color: #33cdd1;
  margin: 0 auto;
`
const TitleTextContainer = styled.div`
  display: grid;
  grid-template-columns: 80%;
  justify-content: center;
  padding: 25px 15px;
`

const TitleText = styled.p`
  font-size: 18px;
  text-align: center;
  color: #3d4d69;
  @media (max-width: 1400px) {
    font-size: 14px;
  }
`
const FeaturesCompareContainer = styled.div`
  max-width: 1250px;
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr;
  padding: 30px;
  /* justify-items: center; */
  margin: 0 auto;
  :nth-of-type(odd) {
    background-color: #f8f9fc;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 450px) {
    padding: 25px 5px;
  }
`

const FeatureCompareElement = styled.div`
  margin: 0;
  font-size: ${props => (props.title ? `22px` : `16px`)};
  padding: 0 15px;
  display: grid;
  align-content: center;
  justify-content: ${props => props.icon && `center`};
  font-weight: ${props => props.title && `700`};
  @media (max-width: 450px) {
    padding: 0 5px;
  }
`

const BrandComponent = styled.div`
  display: grid;
  grid-row-gap: 15px;
  padding: 0 20px;
  @media (max-width: 450px) {
    padding: 0 5px;
  }
`
const FreeTrialCapsule = styled.div`
  color: white;
  font-size: 14px;
  background: ${props => (props.freeTrial ? `#4DBB9D` : `#F95663`)};
  border-radius: 17px;
  padding: 8px 20px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  text-align: center;
  @media (max-width: 450px) {
    padding: 8px 10px;
  }
`

const FreeTrialButton = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  padding: 20px 35px;
  border-radius: 6px;
  border: 1px solid #33cbcf;
  font-size: 18px;
  margin: 5vh auto;
  @media (max-width: 1400px) {
    font-size: 16px;
  }
`
const PlanCardContainer = styled.div`
  display: grid;
  grid-template-columns: 520px 520px;
  grid-column-gap: 40px;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 400px;
    grid-row-gap: 20px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const PLanCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #c2cce1;
  border-radius: 30px;
  grid-row-gap: 35px;
  padding: 35px 0;
`

const PlanCardTableGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px 25px;
  padding: 0 35px;
`

const PlanCardText = styled.p`
  margin: 0;
  font-size: ${props => (props.title ? `36px` : `16px`)};
  font-weight: ${props => props.title && `700`};
  padding: ${props => props.title && `0 35px`};
`

const FeatureCompareRow = props => (
  <FeaturesCompareContainer>
    <FeatureCompareElement>{props.featureText}</FeatureCompareElement>
    <FeatureCompareElement icon>
      {props.element.groupboss}
    </FeatureCompareElement>
    <FeatureCompareElement icon>
      {props.element.groupspro}
    </FeatureCompareElement>
  </FeaturesCompareContainer>
)

export default ({ data, location }) => (
  <>
    <SEO
      title="Groups Pro Alternative and Review in 2024"
      description="This article showed why people look for Groups Pro alternatives. Learn why Groupboss is the best Groups Pro alternative with features comparison."
      pathname={location.pathname}
    />

    <HomeFeature
      homeH1="Groupboss vs Groups Pro"
      homeText="Groupboss gives you more flexibility and features than Groups Pro for generating unlimited leads from Facebook Groups."
      image={
        <GatsbyImage
          image={data.artwork_alternative.childImageSharp.gatsbyImageData}
          loading="eager"
          alt="groupspro alternative"
        />
      }
      homeSectionGrid=""
      homeSectionJustify=""
    />

    <TitleGrid>
      <Title>Why Should you choose Groupboss over Groups Pro?</Title>
      <Bottomline />
    </TitleGrid>
    <TitleTextContainer>
      <TitleText>
        Groupboss helps you to collect leads in Google Sheet, Dashboard and
        email autoresponders with a single click only. On the other hand you can
        collect leads in the google sheet only by using Groups Pro and you need
        zapier to send them in autoresponders, which is a time consuming
        process.
      </TitleText>
    </TitleTextContainer>
    <FeaturesCompareContainer>
      <FeatureCompareElement title>Features</FeatureCompareElement>
      <BrandComponent>
        <GatsbyImage
          image={data.gb_logo.childImageSharp.gatsbyImageData}
          alt="groupboss"
        />
        <FreeTrialCapsule freeTrial>Free trial available</FreeTrialCapsule>
      </BrandComponent>
      <BrandComponent>
        <div>
          <GatsbyImage
            image={data.gby_logo.childImageSharp.gatsbyImageData}
            alt="gby_logo"
          />
        </div>
        <FreeTrialCapsule freeTrial>Free trial available</FreeTrialCapsule>
      </BrandComponent>
    </FeaturesCompareContainer>

    <FeatureCompareRow
      featureText="Googlesheet Integration"
      element={{
        groupboss: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
        groupspro: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
      }}
    />
    <FeatureCompareRow
      featureText="Dashboard"
      element={{
        groupboss: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
        groupspro: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
      }}
    />
    <FeatureCompareRow
      featureText="Integration with Autoresponders"
      element={{
        groupboss: 25,
        groupspro: 3,
      }}
    />
    <FeatureCompareRow
      featureText="Zapier Required"
      element={{
        groupboss: "No (Optional)",
        groupspro: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="Required except 3 integration"
          />
        ),
      }}
    />
    <FeatureCompareRow
      featureText="Custom & Lookalike Audience"
      element={{
        groupboss: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
        groupspro: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
      }}
    />
    <FeatureCompareRow
      featureText="Download all data from dashboard in CSV format"
      element={{
        groupboss: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
        groupspro: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
      }}
    />
    <FeatureCompareRow
      featureText="Support in Email"
      element={{
        groupboss: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
        groupspro: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
      }}
    />
    <FeatureCompareRow
      featureText="Chat Support"
      element={{
        groupboss: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
        groupspro: "Not Available",
      }}
    />
    <FeatureCompareRow
      featureText="Refund Policy"
      element={{ groupboss: "14 Days", groupspro: "30 Days" }}
    />
    <FeatureCompareRow
      featureText="Exclusive support through zoom/skype/Gmeet"
      element={{
        groupboss: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
        groupspro: (
          <GatsbyImage
            image={data.cross.childImageSharp.gatsbyImageData}
            alt="cross"
          />
        ),
      }}
    />
    
    <FeatureCompareRow
      featureText="Free Set Up Call"
      element={{
        groupboss: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
        groupspro: (
          <GatsbyImage
            image={data.tick.childImageSharp.gatsbyImageData}
            alt="tick"
          />
        ),
      }}
    />

    <TitleGrid>
      <Title>Find out how GroupBoss saves you time and money?</Title>
      <Bottomline />
    </TitleGrid>

    <Link
      to="/pricing"
      target="_blank"
      style={{ textDecoration: "none", color: "black" }}
    >
      <div style={{ display: `grid` }}>
        <FreeTrialButton>Get 7 days Free Trial</FreeTrialButton>
      </div>
    </Link>

    <PlanCardContainer>
      <PLanCard>
        <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
          <GatsbyImage
            image={data.gb_logo.childImageSharp.gatsbyImageData}
            alt="groupboss"
          />
        </div>
        <PlanCardText title>
          $45
          <span style={{ fontSize: `22px`, fontWeight: `400` }}> / quarter</span>
        </PlanCardText>
        <PlanCardTableGrid>
          <PlanCardText>Type of Plan:</PlanCardText>
          <PlanCardText>Groupboss Quarterly</PlanCardText>
          <PlanCardText>No of Groups:</PlanCardText>
          <PlanCardText>3</PlanCardText>
        </PlanCardTableGrid>
        {/* new added end */}
        <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
          <PlanCardText title>
            $99
            <span style={{ fontSize: `22px`, fontWeight: `400` }}> / year</span>
          </PlanCardText>
        </div>
        <PlanCardTableGrid>
          <PlanCardText>Type of Plan:</PlanCardText>
          <PlanCardText>Groupboss Yearly</PlanCardText>
          <PlanCardText>No of Groups:</PlanCardText>
          <PlanCardText>3</PlanCardText>
        </PlanCardTableGrid>

        <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
          <PlanCardText title>
            $189
            <span style={{ fontSize: `22px`, fontWeight: `400` }}> / year</span>
          </PlanCardText>
        </div>
        <PlanCardTableGrid>
          <PlanCardText>Type of Plan:</PlanCardText>
          <PlanCardText>Groupboss Yearly (Mega Plan)</PlanCardText>
          <PlanCardText>No of Groups:</PlanCardText>
          <PlanCardText>20</PlanCardText>
        </PlanCardTableGrid>
      </PLanCard>

      <PLanCard>
        <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
          <GatsbyImage
            image={data.gby_logo.childImageSharp.gatsbyImageData}
            alt="gby_logo"
          />
        </div>
        {/* new */}
        <PlanCardText title>
          $20
          {/* <span style={{ fontSize: `22px`, fontWeight: `400` }}> / month</span> */}
        </PlanCardText>
        <PlanCardTableGrid>
          <PlanCardText>Type of Plan:</PlanCardText>
          <PlanCardText>Monthly</PlanCardText>
          <PlanCardText>No of Groups:</PlanCardText>
          <PlanCardText>Unlimited</PlanCardText>
        </PlanCardTableGrid>
        {/* new end */}

        <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
          <PlanCardText title>No</PlanCardText>
        </div>
        <PlanCardTableGrid>
          <PlanCardText>Type of Plan:</PlanCardText>
          <PlanCardText>Yearly</PlanCardText>
          <PlanCardText>No of Groups:</PlanCardText>
          <PlanCardText>No</PlanCardText>
        </PlanCardTableGrid>

        <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
          <PlanCardText title>$297</PlanCardText>
        </div>
        <PlanCardTableGrid>
          <PlanCardText>Type of Plan:</PlanCardText>
          <PlanCardText>One-Time</PlanCardText>
          <PlanCardText>No of Groups:</PlanCardText>
          <PlanCardText>Unlimited</PlanCardText>
        </PlanCardTableGrid>
      </PLanCard>
    </PlanCardContainer>
    <PopularIntegrations />
    
    <CardContainer>
      <CommonCard
        cardTextWeight="500"
        cardText="24/7 Support by real people"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="100% Secure payment"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="Best customer experience"
        cardPadding="13px 25px"
      />
    </CardContainer>

    <BgImgComp />
  </>
)

export const query = graphql`
  {
    artwork_alternative: file(
      relativePath: { eq: "groupboss-groupspro-artwork.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo: file(relativePath: { eq: "groupboss-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gby_logo: file(relativePath: { eq: "groupspro-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    tick: file(relativePath: { eq: "tick_2.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
    cross: file(relativePath: { eq: "cross.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
  }
`
